import banner from '../images/banners/homepage.png';
import stepOne from '../images/Step1.png';
import stepTwo from '../images/Step2.png';
import stepThree from '../images/Step3.png';
import stepFour from '../images/Step4.png';
import stepFive from '../images/Step5.png';
import Budget from '../images/Budget.png';

function step1() {
    return `
        <div class="square-container">
            <img class="fill icon-15" src="${stepOne}"> 
            ${listGenerator('Program Activation','step-one')}
        </div>
    `
}

function step2() {
    return `
        <div class="square-container">
            <img class="fill icon-15" src="${stepTwo}"> 
            ${listGenerator('Research & Development','step-two')}
        </div>`;
}

function step3() {
    return `
        <div class="square-container">
            <img class="fill icon-15" src="${stepThree}">
            ${listGenerator('Implementation','step-three')}
        </div>`;
}

function step4() {
    return `
        <div class="square-container">
            <img class="fill icon-15" src="${stepFour}"> 
            ${listGenerator('Wrap Up','step-four')}
        </div>`;
}

function step5() {
    return `
        <div class="square-container-normal">
            <img class="fill icon-15" src="${stepFive}" > 
        </div>`;
}

function budget() {
    return `
        <div class="square-container">
            <img class="fill icon-15" src="${Budget}"> 
            ${listGenerator('Request, Tracking, & Reporting','budget-management')}
        </div>`;
}

function listGenerator(header, elementID) {
    let element = `
    <li class="list-group-item text-bg-light"><b>Resources</b>
        <ul id="${elementID}-resources" class="list-group list-group-flush">
        </ul>
    </li>
    <li class="list-group-item text-bg-light"><b>Items Tracked</b>
        <ul id="${elementID}-tracking" class="list-group list-group-flush">
        </ul>
    </li>
    `;
    element = elementID !== "step-five" ? element : `dashboard`;
    return `
        <div class="list-description">
            <p class="display-4 pt-5 px-5">${header}</p>
            <ul class="list-group list-group-flush px-5 rounded">
                ${element}
            </ul>
        </div>
    `;
}

export {
    banner,
    step1,
    step2,
    step3,
    step4,
    step5,
    budget
};
